
@import "~@/css/variables";
@import "~@/css/grid";
.ios, .md{
  .panel-sect{
    height: 266px;
  }
  @include mobile-down{
    .v-spacer-35px{
        height: 15px;
    }
  }
}
