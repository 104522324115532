/* =============================
           位置、排版
============================= */
@mixin absolute($top, $right, $bottom, $left) {
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin pos-center($translateX: -50%, $translateY: -50%) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate($translateX, $translateY);
}

@mixin flex($justify-content: center, $align-items: center) {
    display: flex;
    justify-content: $justify-content;
    align-items: $align-items;
}

// overflow hidden
@mixin size-overflow-hidden($w, $h) {
    @include size($w, 0);
    padding-bottom: $h;
    overflow: hidden;
}
/* =============================
         位置、排版(end)
============================= */


/* =============================
          文字、字數限制
============================= */
// 行數限制
@mixin text-lines-ellipsis($line-height, $max-lines) {
    display: -webkit-box;
    overflow: hidden;
    line-height: $line-height;
    white-space: normal;
    text-overflow: ellipsis;
    -webkit-line-clamp: $max-lines;
    /*!autoprefixer:off*/
    -webkit-box-orient: vertical;
    /*autoprefixer:on*/
}

// 字數寬度限制
@mixin text-ellipsis($w: 100%) {
    width: $w;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

// FontAwesome 樣式
@mixin fa-icon($unicode) {
    content: $unicode;
    font-family: 'FontAwesome';
}

/* =============================
        文字、字數限制(end)
============================= */


/* =============================
              圖片
============================= */
// 圖片背景
@mixin bg-img($img, $x: 50%, $y: 50%, $bgSize: cover) {
    background-image: url($img);
    background-position: $x $y;
    background-repeat: no-repeat;
    background-size: $bgSize;
}

// 限制圖片比例
@mixin bg-rwd-img($img, $w, $h) {
    @include over-hidden($w,$h);
    @include size-overflow-hidden($img);
}
/* =============================
            圖片(end)
============================= */


/* =============================
              其他
============================= */
// 尺寸
@mixin size($w, $h: $w) {
    width: $w;
    height: $h;
}

// 動畫
@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }

    @keyframes #{$name} {
        @content;
    }
}

// FontAwesome 樣式
@mixin fa-icon($unicode) {
    content: $unicode;
    font-family: 'FontAwesome';
}


@mixin icomoon($content) {
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	font-size: 20px;
	content:$content;
}
/* =============================
            其他(end)
============================= */