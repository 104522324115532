
@import "~@/css/variables";
@import "~@/css/mixins";

.base-radio,
.base-checkbox {
    display: flex;
    align-items: center;


    &.size-24px{
        .icon-checkbox-wrap{
            width: 22px;
            height: 22px;
        }
    }

    &.custom-control-inline{
        display: inline-flex;
    }

    &:not(:last-child) {
        margin-right: 10px;
    }

    input {
        display: none;

        &:checked {
            ~ .icon-radio-wrap, ~ .icon-checkbox-wrap {
                border-color: $color-primary;
                background-color: $color-primary;

                .icon {
                    opacity: 1;
                }

                ~ .radio-text {
                    color: $color-title;
                }
            }
        }
    }

    .icon-radio-wrap,
    .icon-checkbox-wrap {
        display: inline-block;
        @include size(18px);
        margin-right: 5px;
        border: 1px solid #DDDDDD;
        border-radius: 50px;
        position: relative;
        transition: all 0.3s ease;
    }

    .icon-radio-wrap {
        border-radius: 50%;
        i {
            width: 8px;
            height: 8px;
            background: #fff;
            border-radius: 50%;
        }
    }

    .icon-checkbox-wrap {
        border-radius: 5px;
    }

    .icon {
        @include pos-center;
        font-size: 18px;
        color: $color-panel-bg;
        opacity: 0;
        transition: all 0.3s ease;
    }

    .radio-text,
    .checkbox-text {
        color: $color-font;
    }
}
