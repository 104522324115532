
@import "~@/css/variables";

.base-input {
    margin-bottom: 20px;  
    font-size: 1rem;
    .item-input.item-input-focused .item-input-wrap:after{
        display: none;
    }

    input, select, textarea{
        max-width: 100%;
        flex-shrink: 1;
        flex-grow: 1;
        border: 0;
        font-size: 1em;
    }
    input, select{
        height: 44px;
    }

    // 子項目
    .base-input{
        margin-bottom: 0;
    }
    // 子項目 /

    .item-content {
        padding: 0;
    }

    .item-title{
        overflow: visible;
    }

    .item-label{
        font-size: 1em;
        margin-bottom: 10px;

        .label-txt{
            display: inline-block;
            position: relative;
        }
    }

    .item-inner {
        padding: 0;
        display: block;
        &::after {
            display: none;
        }
    }

    .item-input-wrap{
        // input select textarea 位置
        flex-grow: 1;
        max-width: 100%;
        min-width: 0;
        &:after{
            display:none!important;
        }
    }

    // uderline
    &.sty-underline{

        input, select {
            height: 39px;
            padding: 0;
        }
        .item-title{
            margin-bottom: 0;
            line-height: 1.2;
        }
        .item-content{
            border-bottom: 1px solid #DDDDDD;
        }
        .item-input-wrap {
            margin-top: 0!important;
            margin-bottom: 0 !important;
            display: flex;
            position: relative;
            box-sizing: border-box;
            transition: border .3s;
            .icon {
                font-size: 20px;
                color: #A8A8AB;
                margin-top: 12px;
                margin-right: 10px;
                transition: .3s;
            }
    
            // icon 特別調整
            .icon-phone-call{
                font-size: 17px;
            }
        }

        // 其他
        .btn-get-code{
            margin-top: 4px;
            width:100px;
            font-size: 14px;
            flex-shrink: 0;
            height: 30px;
        }
    }
    // uderline /

    // shadow bar
    &.sty-shadowbar{

        &:focus-within{
            .item-input-wrap{
                border: 1px solid #D5A240;
                i {
                    color: #D5A240;
                }
            }
        }

        .item-input-wrap {
            margin-top: 0!important;
            margin-bottom: 0 !important;
            border: 1px solid #fff;
            display: flex;
            background: #fff;
            border-radius: 10px;
            padding: 0 10px;
            position: relative;
            box-sizing: border-box;
            box-shadow: 0 3px 10px #3333331A;
            transition: border .3s;

            .icon {
                font-size: 20px;
                color: #A8A8AB;
                margin-top: 12px;
                margin-right: 10px;
                transition: .3s;
            }
    
            // icon 特別調整
            .icon-phone-call{
                font-size: 17px;
            }
        }
    }
    // shadow bar /

    // custom-controls
    &.sty-custom-controls{
        .item-title{
            margin-bottom: 10px;
        }
        .item-input-wrap-outer{
            display: none;
        }
        .base-radio{
            margin-bottom: 10px;
            font-size: 14px;
        }
    }

    .custom-controls-wrap{
        width: 100%;
    }
     // custom-controls /

    // select
    &.sty-select{
        .item-input-wrap-outer{
            position: relative;
        }
        .select-chevron-down{
            position: absolute;
            font-size: 16px;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-weight: bold;
            color: $color-primary;
        }
    }
    // select /

    .item-input-wrap-outer{
        display: flex;
        width: 100%;
    }

    // 星星
    .star-before, .star-after{
        position:absolute;
        color: #FF7589;
        top: 0;
    }
    .star-before{
        right: 100%;
    }
    .star-after{
        left: 100%;
    }
    // 星星 /
    
}

.error-msg {
    padding-top: 5px;
    display: inline-block;
    color: $color-primary;
    font-size: 1em;
}
