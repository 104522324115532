
  html {
    opacity: 0;
    visibility: hidden;

    &.is-view-ready{
      transition:.3s;
      opacity: 1;
      visibility: visible;
    }
  }
