
@import "~@/css/variables";
@import "~@/css/mixins";
  // img-fallback
  .img-fallback{
    background-image:url(~@/assets/images/general/shine.png); 
    background-size: 155% 100%;
    img {
      position: absolute;
      top: 50%;
      right: 3%;
      width: 54%;
    }

    &.abs-fill{
        @include absolute(0,0,0,0);
    }
  }
  // img-fallback /
