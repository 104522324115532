
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";
    // no-data-box
.no-data-box{
    @include flex(center,center);
    flex-direction: column;
    padding:10px;
    border-radius: 10px;
    .no-data-icon{
        background: currentColor;
        @include flex(center,center);
        background:none;
        max-width: 180px;
        border-radius: 50%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 25px;
        img{
        }
    }
    .no-data-title{
        font-size: 24px;
        font-weight: bold;
        color:currentColor;
        text-align: center;
        margin-bottom: 5px;
    }
    .no-data-des{
        font-size: 16px;
        color:currentColor;
        text-align: center;
    }

    &.sty-page{
        margin-top: 50px;
    }

    &.size-sm{
        .no-data-icon{
            max-width: 110px;
            margin-top: 15px;
        }
        .no-data-title{
            font-size: 18px;
            margin-bottom: 0;
        }
        .no-data-des{
            font-size: 14px;
        }
    }

    @include media(374.98){
        &:not(.size-sm) {
            .no-data-icon{
                max-width: 160px;
            }
        }
    }

    // <div class="section-simple">
    //     <div class="no-data-box">
    //         <div class="no-data-icon">
    //             <img src="/assets/images-m/icon/joblink123.svg" alt="" srcset="">
    //         </div>
    //         <div class="no-data-title">
    //             目前尚無特殊營業日
    //         </div>
    //     </div>
    // </div>
}
  
