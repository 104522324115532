/* =============================== 
              Colors
=============================== */
$color-primary: #D5A240;
$color-secondary: #5B5B5B;
$color-bg: #F9F9F9;
$color-panel-bg: #fff;
$color-border: #ddd;
$color-title: #333333;
$color-gray: #dedede;

// 刪除
$color-delete:#E9545D;

// 文字
$color-font: #333333;

// 分隔線
$color-divider-line: #E3E3E3;

// 媒體背景
$color-media-bg: #dedede;

// 錢
$color-money: $color-primary;
$color-money-hilight:#D50000;


/* =============================== 
              Fonts
=============================== */
$fz-h1: 32px;
$fz-h2: 24px;
$fz-h3: 18.72px;
$fz-h4: 16px;
$fz-h5: 13.28px;
$fz-mini: 12px;

// Nunito (英文)
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap');
$ff-nunito: 'Nunito', "微軟正黑體", "Microsoft JhengHei" , sans-serif;

$ff-note:
'Nunito',
"微軟正黑體",
"Microsoft JhengHei",
sans-serif,
"黑體",
"蘋果儷中黑";

$ff-msjh: "微軟正黑體", "Microsoft JhengHei";