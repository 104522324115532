/* Custom color theme properties */
.ios, .md {
  --inital-root-font-size: 14px;
  --f7-theme-color: #333;
  --f7-theme-color-rgb: 245, 245, 245;
  --f7-theme-color-shade: #e1e1e1;
  --f7-theme-color-tint: #f5f5f5;

  // 修改預設值
  --f7-page-bg-color:#F5F5F5;
  --f7-navbar-title-text-align: center;
  --f7-input-font-size: 14px;
  --f7-input-height: 44px;
  --f7-input-font-sizecrop_picture_wrap: 14px;
  --f7-button-font-weight: 400;
  --f7-toolbar-font-size: 16px;
  --f7-toolbar-height: 63px;
  --f7-sheet-bg-color: #cfd5da;
  --f7-picker-item-selected-bg-color: none;
  // 修改預設值 /
}

/* Your app custom styles here */
@import 'variables.scss';
@import 'mixins.scss';
@import 'function.scss';
@import 'grid.scss';
@import 'icons.css';
@import 'icomoon/style.css';
// variable /


/* =============================
        BASIC STYLE
============================= */
#app{
  max-width: 768px;
  margin: 0 auto;
}

/* =============================
        BASIC STYLE(END)
============================= */

// 模組
// 圖片裁切
.popup-crop_picture{

    // 大頭照
    &.sty-headshot{
        #crop_picture_preview {
            border-radius: 50%;

            .cr-boundary{
                // 50% border radius
                border-radius: 50%;
            }
        }

        button#crop_picture_select_btn{
            right: 50%;
            transform: translateX(175px) translateY(0);

            @media screen and (max-width: 699px) {
                right: 13%;
                transform: translateX(0);
            }
        }
    }

    .page-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .crop_picture_block{
        margin-top: 15px;
        margin-bottom: 0;
    }

    .explain-wrap{
        text-align: center;
        margin-bottom: 35px;
    }

}
#crop_picture_preview {
    touch-action: pan-y;
    width: 80%;
    background: -webkit-gradient(linear, left top, left bottom, from(#333333), color-stop(100%, #666666), to(#666666));
    background: linear-gradient(#333333 0%, #666666 100%, #666666 100%);
    margin-left: 5px;
    margin-right: 5px;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 0 0 0 13px $color-primary;
    max-width: 350px;
    // 裁切 overflow hidden失效處理
    position: relative;
    .cr-boundary{
        overflow: hidden;
        z-index: 0;
    }
    .cr-viewport{
        // 避免套件預設 會導致 白邊
        border:0;
    }// 裁切 overflow hidden失效處理 (end)

}

#crop_picture_select_group{
    margin:0;
    padding: 13px 0;
}

button#crop_picture_select_btn {
    background: $color-primary;
    border: 0;
    font-size: 30px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(50%);
    z-index: 10;
    @include flex(center,center);
}
button#crop_picture_rotate_btn {
  i {
    margin-right: 5px;
    margin-top: -1px;
  }
}
.crop_picture_title {
    font-size: 20px;
    font-weight: bold;
    color: $color-primary;
    margin: 40px 0 10px;
}

.crop_picture_tip {
    color: $color-font;
    font-size: 16px;
}

#crop_picture_upload_btn {
    border: 0;
    margin: 0 auto;
    line-height: 44px;
    height: 44px;
    font-size: 16px;
    color: #fff;
    letter-spacing: 5px;
}


.crop_picture-btn-wrap {
    button, #crop_picture_upload_btn{
        width: 100%;
    }

    button i.btn-icon{
        font-size: 1.08em;
    }
}

.crop_picture_wrap {
    position: relative;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}
// 模組 /

// common
.ios, .md{
  body{
    color: #333333;
    background: #efefef;
    font-family: $ff-note;
  }
  img {
      max-width: 100%;
  }
  .page-inner{
    padding: 0 15px;
  }
  // common /

  // i
  .i-before{
    margin-right: 5px;
  }
  // i /
  
  // btn
  .button{
    font-size: 16px;
    &.size-h-45px{
      height: 45px;
    }

    &.size-h-40px{
      height: 40px;
    }
  
    &.radius-10px{
      border-radius: 10px;
    }

    &.shadow{
      box-shadow: 0 3px 10px #33333333;
    }

    &.active-state {
      opacity: .7;
    }
  }
  
  .btn-pill{
    border-radius: 22px;
  }
  
  .btn-theme{
    background: #D5A240;
    color:#fff;
  }
  .btn-gt-theme{
    background: linear-gradient(90deg, #B88931, #F3C454);
    color:#fff;
  }
  
  .btn-outline-theme{
    background:#fff;
    border: 1px solid $color-primary;
    color: $color-primary;
  }

  .btn-outline-gray{
    background: #F5F5F5;
    border: 1px solid #A8A8AB;
    color: #707070;
  }

  .btn-float{
    border: 1px solid #fff;
    background: linear-gradient(136deg, #FFFFFF, #EEEEEE);
    box-shadow: 5px 5px 11px rgba(51, 51, 51, 0.15);
  }
  
  .scan-btn{
    background: linear-gradient(180deg, #FDFDFC, #EBEBEB);
    border: 2px solid #FFFFFF;
    border-radius: 18px;
    box-shadow: 0 3px 6px #00000029;
    font-size: 14px;
    width: 140px;
    max-width: 100%;
    height: 36px;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    text-align:center;
    .btn-icon{
        margin-right: 10px;
    }
  }

  .toolbar-btn{
    font-size: 14px;
    height: 40px;
  }
  // btn /
  
  // spacer
  // v-spacer 垂直空間撐開
  @each $v-spacer-val in (3,4,5,7,10,15,20, 25,30, 35, 40, 50, 60,70) {
      .v-spacer-#{$v-spacer-val}px {
          height: #{$v-spacer-val}px;
      }
  }
  // spacer /
  
  // margin
  .mb-5px{
    margin-bottom: 5px!important;
  }
  .mb-10px{
    margin-bottom: 10px!important;
  }
  .ml-5px{
    margin-left: 5px!important;
  }
  .ml-10px{
    margin-left: 10px!important;
  }
  .mr-20px{
    margin-right: 20px!important;
  }
  .mx-10px{
    margin-left: 10px;
    margin-right: 10px;
  }

  .mt--20px{
    margin-top: -20px;
  }
  
  .pb-10px{
    padding-bottom: 10px!important;
  }
  .pt-20px{
    padding-top: 20px!important;
  }
  .pb-5px{
    padding-bottom: 5px!important;
  }
  .px-5px{
    padding-left: 5px!important;
    padding-right: 5px!important;
  }
  .px-7px{
    padding-left: 7px!important;
    padding-right: 7px!important;
  }
  .px-8px{
    padding-left: 8px!important;
    padding-right: 8px!important;
  }
  .px-10px{
    padding-left: 10px!important;
    padding-right: 10px!important;
  }
  .px-18px{
    padding-left: 18px!important;
    padding-right: 18px!important;
  }
  // margin /
  
  // lift
  
  // lift /

  // width
  .w-100px{
    width: 100px;
  }
  
  .w-fixed-100px{
    width: 100px;
    min-width: 100px;
  }
  // width/
  
  // bg
  .bg-shine{
    background: url(~@/assets/images/general/shine.png);
    background-size: cover;
    background-position: center;
  }
  
  .bg-white{
    background:#fff!important;
  }
  
  .page-bg-shine{
    .page-content{
      background: url(~@/assets/images/general/shine.png);
      background-size: 155%;
      background-position: left top;
    }
  }
  
  .page-bg-gradient-gold{
    .page-content{
      background: url(~@/assets/images/general/gold-bg@2x.png), #fff;
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  // bg /
  
  // text
  .text-bold{
    font-weight: bold!important;
  }

  .letter-spacing-2px{
    letter-spacing: 2px;
  }
  
  .text-link{
    color: #D5A240!important;
  }
  
  .text-white{
    color:#fff!important;
  }
  
  .text-gray{
    color: #707070!important;
  }
  
  .text-notice{
    color: #D5A240!important;
  }
  
  .text-money{
    color:$color-primary!important;
  }

  .text-money-hilight{
    color: #D50000!important;
  }

  .text-danger{
     color: #D50000!important;
  }
  
  .text-center{
    text-align: center;
  }
  
   // text
  .fz-12{
      font-size: 12px!important;
  }
  .fz-13{
      font-size: 13px!important;
  }
  .fz-14{
      font-size: 14px!important;
  }
  .fz-15{
      font-size: 15px!important;
  }
  .fz-16{
      font-size: 16px!important;
  }
  .fz-17{
      font-size: 17px!important;
  }
  .fz-20{
      font-size: 20px!important;
  }
  .fz-28{
      font-size: 28px!important;
  }
  
  // text /
  
  // link
  .link-underline{
    color: #298fff;
    border-bottom: 1px solid #298fff;
  }

  .link-underline-after{
    position:relative;
    &:after{
      content:'';
      display:block;
      position:absolute;
      top: calc(100% + 0.5px);
      width:100%;
      height: 1px;
      background: currentColor;
    }
  }
  // link /
  
  // measure
  .h-100{
    height: 100%!important;
  }
  // measure /
  
  
  // trans
  .trans-up-10px{
      transform: translateY(-10px);
  }
  // trnas /
  
  
  // page-content-inner
  .page-content-inner{
    padding-top: 15px;
    padding-bottom: 15px;
    box-sizing: border-box;
  }
  // page-content-inner /
  
  // page-inner
  .page-inner-10{
    padding-left: 10px;
    padding-right: 10px;
  }
  .page-inner-20{
    padding-left: 20px;
    padding-right: 20px;
  }
  .page-inner-rwd-20{
    padding-left: 20px;
    padding-right: 20px;
  }
  .page-inner-24{
    padding-left: 24px;
    padding-right: 24px;
  }
  .page-inner-30{
    padding-left: 30px;
    padding-right: 30px;
  }
  .page-inner-rwd-30{
    padding-left: 30px;
    padding-right: 30px;
  }
  .page-inner-45{
    padding-left: 45px;
    padding-right: 45px;
  }

  @include media(374.98) {
    .page-inner-rwd-20{
      padding-left: 10px;
      padding-right: 10px;
    }
    .page-inner-rwd-30{
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  // page-inner /
  
  
  // panel-block
  .panel-block{
    overflow:hidden;
    height: 100%;
    display: flex;
    flex-direction: column;

    .panel-title{
      font-family: $ff-nunito;
      margin:0;
      margin-bottom: 15px;
    }
  
    .panel-main{
      padding-top: 15px;
      box-shadow: 0 2px 24px rgba(0, 0, 0, 0.16);
      flex-grow: 1;
      background: linear-gradient(180deg, #FDFDFC, #EBEBEB);
      position: relative;
      border-radius: 30px 30px 0 0;
      flex-grow: 1;
    }
    
    .panel-slash{
      pointer-events: none;
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 0;
      min-width:100%;
      min-height: 100%;
      border-radius: 30px 30px 0 0;
    }
  
    // 其他
    .beauty-agent-sect{
      height: 226px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      // &:before{
      //   content:'';
      //   background: url(/src/assets/images/general/shine-552.png);
      //   background-size: 149%;
      //   background-position: left 101%;
      //   position: absolute;
      //   left:0;
      //   right:0;
      //   top: 0;
      //   bottom: -40px;
      //   z-index: 0;
      // }
  
      .beauty-agent-sect-content{
        position:relative;
        z-index:1;
      }
    }
    // 其他 /
  }
  // panel-block /
  
  
  // identify-wrapper
  .identify-wrapper{
    @include flex(center,center);
  }
  // identify-wrapper /
  
  // flex-center
  .flex-xy-center{
    @include flex(center,center);
  }
  // flex-center /

  // auto-grow-wrap
  .auto-grow-wrap{
      min-height:100%;
      flex-direction: column;
      display: flex;
      .auto-grow-head, .auto-grow-fixed, .auto-grow-static{
          flex-shrink:0;
      }
      .auto-grow-body{
        flex-grow: 1;

        &.auto-scroll{
          overflow-y: auto;
        }

        &.sty-enable-child-full-h{
          display: flex;
          flex-direction: column;
        }
      }
  }
  // auto-grow-wrap /
}

// common


// 內建
.ios, .md{

  // page
  .page{
    &.no-toolbar.no-toolbar-pd{
      .page-content{
        padding-bottom: 0;
      }
    }

    &.page-content-under-nav{
      .page-content{
        padding-top: 0;
      }
    }
  }

  // page-content
  .page-content{
    // overflow-y: scroll;
  }
  // page-content /

  // navbar
  .is-scrolling .navbar {
  }
  .navbar {
    font-size: 16px;

    
    &.sty-both-side-100px{
      .left, .right{
          width: 100px;
      }
  }

    &:not(.navbar-transparent) {
      box-shadow: 0 -2px 30px #011F3F1A;
    }
    
    .navbar-inner{
      padding-left: 15px;
      padding-right: 15px;
    }

    // 內建
    &.navbar-transparent{
      box-shadow: none
    }
    // 內建 /

    &.transparent-bg{
      box-shadow: none;
      .navbar-bg{
        display: none;
      }
    }

    &.sty-index{
      .left{
        width:auto;
        max-width: 150px;
      }
      .right{
        margin-right: 0;
      }
    }

    .navbar-bg{
      background: #fff;
      &:before, &:after{
        display: none;
      }
    }
    .title{
      font-weight: normal;
    }


    .left{
      justify-content: flex-start;
      text-align: left;
    }

    .left, .right{
      width: 40px;
    }

    .title{
      width: calc(100% - 80px);
      flex-grow: 1;
    }

    .right{
      justify-content: flex-end;
      text-align: right;
    }

    a {
        &.link{
            line-height: 1;
        }
        &.icon-badge-wrap{
          min-width: auto;
        }
      }

    // 其他
    .nav-round-back{
      width: 32px;
      height: 32px;
      background: #A8A8AB;
      color:#fff;
      font-size: 20px;
      border-radius: 50%;
      @include flex(center,center);
      i, .icon {
        font-size: 22px;
        margin-left: -3px;
      }
    }

    // icon
    a.icon-only{
      width: 28px;
    }
  }
  .navbar a.link{
      // override f7 default 
      min-height:auto;
      // icon
      padding: 0;
      i {
        font-size: 28px;
      }
      .icon-envelope{
        font-size: 18px;
      }
      .icon-share{
        font-size: 20px;
      }
      .icon-garbage{
        font-size: 20px;
      }
  }
  // navbar /

  // picker
  .picker-center-highlight{
    &:before{
      content: '';
      position: absolute;
      background-color: #a8abb0;
      display: block;
      z-index: 15;
      top: 0;
      right: auto;
      bottom: auto;
      left: 0;
      height: 1px;
      width: 100%;
      -webkit-transform-origin: 50% 0;
      transform-origin: 50% 0;
    }
    &:after{
      content: '';
      position: absolute;
      background-color: #a8abb0;
      display: block;
      z-index: 15;
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
    }
  }
  &.device-pixel-ratio-3 .picker-center-highlight:after{
    transform: scaleY(.33);
  }
  &.device-pixel-ratio-3 .picker-center-highlight:before{
    transform: scaleY(.33);
  }

  .picker.sheet-modal .toolbar{
    height: 44px;
  }
  // picker /

  // badge
  .badge-theme{
    background: $color-primary;
    color:#fff;
  }

  .badge-outline-primary{
    color: $color-primary;
    border: 1px solid $color-primary;
    background:none;
  }

  .badge-gray{
    background: #DDDDDD;
    color:$color-font;
  }

  // badge /

  // popup
  .popup-backdrop{
    background: rgba(#2A2A2A, .9);
  }
  .popup{
    // notice popup
    &.popup-sty-modal{
        background: rgba(0, 0, 0, .3);
        .page{
            background: none;
        }
        .navbar{
            background: none!important;
        }
        .popup-close{
            i, a{
              color:#fff;
            }
        }
        .page-inner{
          box-sizing: border-box;
        }
        .page-content{
            transition: .3s;
            margin-left: auto;
            margin-right: auto;
            &.popup-modal-centered{
                @include flex(flex-start,center);
            }
            > div{
                margin:auto;
                transform: translateY(- calc(var(--f7-navbar-height) + var(--f7-safe-area-top)));
            }
            .popup-modal-content{
                position: relative;
                // border-radius: $border-radius;
                &:not(.no-bg){
                  background: #fff;
                }
                width: 305px;
                max-width: 100%;
                margin-left: auto;
                margin-right: auto;
                box-sizing: border-box;
            }
        }
        .page-inner{
            max-width: 100%;
        }
    }

    // popup-tail-close
    .popup-tail-close{
      position: absolute;
      left: 50%;
      bottom:0;
      transform: translateX(-50%) translateY(50%);
    }
    // popup-tail-close/
  }

  .popup-recommends-list{
    .head-card{
      .card-head .cell-account{
        padding-left: 6px;
      }
    }

    .cell-account{
      display: inline-block;
      width: calc(100% - 140px);
      box-sizing: border-box;
    }
    .cell-time{
      display: inline-block;
      width: 140px;
      text-align: center;
      box-sizing: border-box;
    }

    .recommends-list{
      font-size: 14px;
      .item{
        padding-top: 10px;
        padding-bottom: 13px;
        border-bottom: 1px solid #EBECEE;
        padding-left: 10px;
        &:first-child{
          padding-top: 0;
        }
      }

      .cell-account{

      }
      .cell-time{
        color: #707070;
      }
    }
  }

  .popup-bonus-list{
    .head-card{
    }

    .card-head{
      display: flex;
      .cell-ex{
          padding-left: 20px;
      }
      .cell-time{
          padding-right: 32px;
      }
    }

    .cell-ex{
      display: inline-block;
      width: 33%;
      box-sizing: border-box;
      text-align: left;
    }
    .cell-bonus{
      width: 33%;
      display: inline-block;
      text-align: center;
      box-sizing: border-box;
    }

    .cell-time{
      box-sizing: border-box;
      width: 33%;
      text-align: right;
    }

    .bonus-list{
      font-size: 14px;
      .item{
        display: flex;
        padding-top: 17px;
        padding-bottom: 5px;
        border-bottom: 1px solid #EBECEE;
        padding-left: 10px;
        padding-right: 10px;
        &:first-child{
          padding-top: 0;
        }
      }

      .cell-bonus{
        color:#0E69AE;
        font-weight: bold;
      }
      .cell-time{
        color: #707070;
        font-size: 12px;
      }
    }
  }
  // popup /

}
// 內建 /

// page
.ios, .md{
  .page-msg{
    .msg-page-content{
    }

    .msg-page-content-inner{
      height: 100%;
      @include flex(center, center);
    }

    .msg-wrap{
      margin-top: -25vh;
    }
  }

  .page-pesudo-panel{

  }
}
// page /


// 新建元件
.ios, .md {

  // bs-grid
  .bs-row{
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    box-sizing: border-box;

    > [class*="bs-col-"] {
      box-sizing: border-box;
      padding: 0 15px;
    }

    &.gutter-4p5px{
      margin-left: -4.5px;
      margin-right: -4.5px;
      > [class*="bs-col-"] {
        padding: 0 4.5px;
      }
    }
  }

  .bs-col-33{
    width: calc(100% / 3);
  }

  .bs-col-25{
    width: calc(100% / 4);
  }

  .bs-col-50{
    width: calc(100% / 2);
  }
  // bs-grid /

  // page-has-panel-block
  .page-has-panel-block{
    &.no-toolbar .page-content{
      padding-bottom: 0;
    }
  }
  // page-has-panel-block /

  // form
  form.list{
    margin:0;
    margin-bottom: 15px;
    ul{
      background:none;
      &:before, &:after{
        display: none;
      }
    }
  }

  .form-group{
    margin-bottom: 13px;
  }
  // form /


  // left-dash-desbox
  .left-dash-desbox{
    position: relative;
    padding-left: 13px;
    margin-bottom: 15px;
    &:before{
      content:'';
      display: block;
      width: 3px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg, #D1A636, #FFD361);
    }
  }
  // left-dash-desbox /

  // focus-formel-card
  .focus-formel-card{
    margin-bottom: 15px;
    padding: 20px;
    background: #fff;
    border-radius: 10px;

    &.is-invalid{
      .card-ipt-wrap{
      }
    }

    .card-title{
      font-size: 16px;
      margin-bottom: 20px;
    }
    .card-ipt-wrap{
      padding: 15px;
      background-color: #FAFAFA;
      border-radius: 10px;
    }
    input{
      font-size: 24px;
      color: #d5a240;
      width:100%;
      text-align: center;
      font-weight:bold;
    }
  }
  // focus-formel-card /

  // left-dash-title
  .left-dash-title{
    margin:0;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-size: 1.142rem;
    font-weight: normal;

    &.inset{
      padding: 0 10px;
    }

    .title-txt{
      position: relative;
      padding-left: 0.8125em;
      &:before{
        content:'';
        display: block;
        width: 3px;
        background: linear-gradient(180deg, #D1A636, #FFD361);
        height: 1.25em;
        margin-right: 10px;
        position: absolute;
        left:0;
        top: 0;
      }
    }

    .title-des{
      font-size: 0.75em;
      color:$color-primary;
      padding-top: 4px;
    }

    &.sty-2side{
      justify-content: space-between;
    }
  }
  // left-dash-title /

  // fixed-bottom-toolbar
  .fixed-bottom-toolbar{
    background:#fff;
    height: 66px;
    box-shadow: 0 -2px 30px #011F3F1A;
    &:before, &:after{
      // md
      display: none;
    }
    .toolbar-inner{
      padding: 0 15px;
      &:before, &:after{
        // ios
        display: none;
      }

    }

    .row{
      width: 100%;
    }

    &.toolbar-px-15px{
    }

    &.sty-selected-info{
      .selected-num{
        color: $color-primary;
      }
      .selected-info{
        flex-shrink: 0;
      }
      .toolbar-btn{
        margin-left: 15px;
        width: 222px;
      }
    }

    &.sty-deposit{
      .row{
        align-items: center;
        flex-wrap: nowrap;
      }
      .deposit-info{
        font-size: 14px;
      }
      .right-col{
        width: 150px;
      }
    }

  }
  // fixed-bottom-toolbar /

  // section-block
  .section-block{
    margin-bottom: 20px;
  }
  // section-block /

  .custom-control-bar{
    border-radius: 10px;
    background: linear-gradient(180deg, #FDFDFC, #EBEBEB);
    border: 3px solid #fff;
    padding: 6px 15px;
    color:$color-font;
    margin-bottom: 15px;
    &.sty-shadow{
      box-shadow: 0 3px 6px #00000029;
    }
    .base-checkbox{
      margin-right: 10px;
    }
  }
  // consent-check-bar /

  .freebies-card{
    background:#fff;
    border-radius: 10px;
    display: flex;
    &:not(.no-shadow) {
      box-shadow: 0 3px 10px #3333331A;
    }

    &.sty-flat{
      box-shadow:none;
      .card-media{
        width: 60px;
        margin-right: 10px;
      }
      .card-main{
        padding: 0;
      }
    }

    .card-media{
      width: calc(113 / 345 * 100%);
      max-width:170px;
      @include flex(center,center);
    }
    .card-main{
      padding: 10px;
      flex-grow: 1;
    }
    .card-inf-bar{
      margin-bottom: 7px;
    }
    .card-title{
      font-size: 1rem;
      &.sty-2side{
        display: flex;
        .card-inf-txt{
          margin-left: auto;
        }
      }
    }
    .title-txt{

    }

    // 其他
    .card-inf-txt{
      color: #A8A8AB;
    }
    .card-badge{
      font-size: 1rem;
      padding: 2.5px 10px;
      height: auto;
      width: auto;
    }
  }
  // freebies-card /

  // article-block
  .article-block{
      min-height: 100%;
      padding: 0 15px;
      // article-block 為min-height
      // 子元素若要 min-hieght 則本身需設為 flex
      display: flex;
      // flex-direction: column;

      // 有 article head
      &.sty-has-head{
          flex-direction: column;
          height: 100%;
          .section-simple, .article-content, textarea, .tox-tinymce{
              flex-grow: 1;
              min-height: auto;
          }
      }
      // 有 article head /

      // column direction
      &.sty-column-direction{
          flex-direction: column;
          height: 100%;
          .section-simple, .article-content, textarea, .tox-tinymce{
              flex-grow: 1;
              min-height: auto;
          }
      }
      // column direction /

      .section-simple, .article-content, textarea, .tox-tinymce{
          min-height: 100%;
          width: 100%;
          box-sizing: border-box;
      }


      .article-title{
        font-size: 1.42857rem;
        margin: 0;
        margin-bottom: 7px;
        font-weight: normal;
      }

      .article-info-bar{
        color: $color-primary;
        font-size: 0.857rem;
        display: flex;
        align-items: center;
        i {
          font-size: 1.142857rem;
          margin-top: -1px;
          margin-right: 5px;
        }
      }

      .article-divider-line{
        height: 1px;
        background: $color-divider-line;
        margin: 16px 0;
      }

  }
  // article-block /

  // payment-custom-control
  .float-card-custom-control-block{
    .block-inner{
      
    }
    .floatcard-custom-control{
      margin-bottom: 10px;
    }
  }

  .floatcard-custom-control, .floatcard{
    --icon-size: 1.51rem;
    position: relative;
    text-align: center;
    display: block;
    line-height: 1.25;
    .control-input{
      display:none;
    }
    .control-input:checked{

        ~ .style-bg-1{
          opacity: 0;
        }

        ~ .style-bg-2{
          opacity: 1;
        }

        ~ .check-indicator{
          opacity:1;
        }

        ~ .control-inner{
          .control-icon{
            color: #644100;
          }
          .control-txt{
            color: #644100;
          }
        }
    }

    &:active{
        .style-bg-1{
          opacity: 0;
        }

        .style-bg-2{
          opacity: 1;
        }

        .check-indicator{
          opacity:1;
        }

        .control-inner{
          .control-icon{
            color: #644100;
          }
          .control-txt{
            color: #644100;
          }
        }
    }


    &.no-checkicon{
      .check-indicator{
        display: none;
      }
    }

    &.inline{
      display: inline-block;
    }

    &.radius{
    }

    &:before{
      content:'';
      display: block;
      padding-top: calc(84 / 98 * 100%);
    }


    .style-bg-1{
      transition: .3s;
      border-radius: 10px;
      overflow: hidden;
      @include absolute(0,0,0,0);
      border: 1px solid #fff;
      background: linear-gradient(136deg,#FFFFFF, #EEEEEE);
      box-shadow: 5px 5px 11px rgba(51, 51, 51, 0.15);
    }

    .style-bg-2{
      transition: .3s;
      opacity: 0;
      @include absolute(0,0,0,0);
      .bg-outer{
        @include absolute(0,0,0,0);
        border-radius: 10px;
        background-image: url(~@/assets/images/general/rectangle2817.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      .bg-inner{
        @include absolute(2px,2px,2px,2px);
        border-radius: 10px;
        background-image: url(~@/assets/images/general/rectangle2816.svg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }


    .control-inner{
      @include absolute(0,0,0,0);
      @include flex(center,center);
      flex-direction: column;
      z-index: 1;
    }

    .control-icon{
      margin-bottom: 7px;
      width: calc(var(--icon-size) * 1.25);
      i {
        font-size: var(--icon-size);
      }
      @include flex(center,center);
    }

    .control-txt{
      font-weight: bold;
      color: #707070;
      padding: 0 3px;
    }

    .check-indicator{
      opacity: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: $color-primary;
      color:#fff;
      @include flex(center,center);
      position: absolute;
      top: 5px;
      right: 5px;
      transition: .3s;
      i {
        font-size: 14px;
        font-weight: bold;
      }
    }

    // icon 處裡
    .control-icon{
      .icon-solid{
        font-size: calc(var(--icon-size) * 1.02625);
      }
      .icon-car{
        font-size: calc(var(--icon-size) * 0.95833);
      }
      .icon-recommend{
        font-size: calc(var(--icon-size) * 1.01);
      }
      .icon-bonus{
        font-size: calc(var(--icon-size) * 0.8679166);
      }
      .icon-password{
          font-size: calc(var(--icon-size) * 1.02875);
      }
      .fa-qrcode{
        font-size: calc(var(--icon-size) * 1.166667);
      }
      
    }

    @include mobile-down{
      --icon-size: 21px;
      .control-icon{
        margin-bottom: 4px;
      }
      .control-txt{
        font-size: 13px;
      }
    }

    @include media-up(550){
      --icon-size: 2rem;
      margin: 5px;
      margin-bottom: 17px;
      // --icon-size: 26px;
      // .control-icon{
      //   width: 30px;
      //   i {
      //     font-size: 30px;
      //   }
      //   .icon-solid{
      //     font-size: 35px;
      //   }
      //   .icon-car{
      //     font-size: 35px;
      //   }
      //   .icon-recommend{
      //     font-size: 35px;
      //   }
      //   .icon-bonus{
      //     font-size: 27px;
      //   }
      //   .icon-password{
      //       font-size: 35px;
      //   }
      //   .fa-qrcode{
      //     font-size: 38px;
      //   }
      // }
    }

    @include media-up(630){
      --icon-size: 2.3rem;
      // .control-icon{
      //   width: 40px;
      //   i {
      //     font-size: 40px;
      //   }
      //   .icon-solid{
      //     font-size: 45px;
      //   }
      //   .icon-car{
      //     font-size: 45px;
      //   }
      //   .icon-recommend{
      //     font-size: 45px;
      //   }
      //   .icon-bonus{
      //     font-size: 37px;
      //   }
      //   .icon-password{
      //       font-size: 45px;
      //   }
      //   .fa-qrcode{
      //     font-size: 48px;
      //   }
      // }
    }
  }
  // payment-custom-control /

  .payment-custom-control{
    position: relative;
    width: 98px;
    text-align: center;
    
    .control-input{
      display:none;
      &:checked {

        ~ .style-bg-1{
          opacity: 0;
        }

        ~ .style-bg-2{
          opacity: 1;
        }

        ~ .check-indicator{
          opacity:1;
        }

        ~ .control-inner{
          .control-txt{
            color: #644100;
          }
        }
      }
    }

    &.inline{
      display: inline-block;
    }

    &.radius{
    }

    &:before{
      content:'';
      display: block;
      padding-top: calc(84 / 98 * 100%);
    }


    .style-bg-1{
      transition: .3s;
      border-radius: 10px;
      overflow: hidden;
      @include absolute(0,0,0,0);
      border: 1px solid #fff;
      background: linear-gradient(136deg,#FFFFFF, #EEEEEE);
      box-shadow: 5px 5px 11px rgba(51, 51, 51, 0.15);
    }

    .style-bg-2{
      transition: .3s;
      opacity: 0;
      @include absolute(0,0,0,0);
      .bg-outer{
        @include absolute(0,0,0,0);
        border-radius: 10px;
        background-image: url(~@/assets/images/general/rectangle2817.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      .bg-inner{
        @include absolute(2px,2px,2px,2px);
        border-radius: 10px;
        background-image: url(~@/assets/images/general/rectangle2816.svg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }


    .control-inner{
      @include absolute(0,0,0,0);
      @include flex(center,center);
      flex-direction: column;
      z-index: 1;
    }

    .control-icon{
      margin-bottom: 7px;
      width: 30px;
      @include flex(center,center);
    }

    .control-txt{
      font-weight: bold;
      color: #707070;
    }

    .check-indicator{
      opacity: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: $color-primary;
      color:#fff;
      @include flex(center,center);
      position: absolute;
      top: 5px;
      right: 5px;
      transition: .3s;
      i {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
  // payment-custom-control /

  // simple-info-block
  .simple-info-block{
    .block-bar{
      + .block-bar{
        margin-top: 7px;
      }
    }
  }
  // simple-info-block /

  // simple-info-table
  .simple-info-table{
    width: 100%;
    th{
      text-align: left;
    }
    td{
      text-align: right;
      color: #A8A8AB;
    }
  }
  // simple-info-table /

  // checkout-info-bar
  .checkout-info-bar{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: $color-primary;
    font-weight: bold;
    margin-bottom: 15px;
    .bar-content{
      font-size: 18px;
    }
  }
  // checkout-info-bar /

  // divider-line
  .divider-line{
    height: 2px;
    background: #E3E3E3;
  }
  // divider-line /

  // icon-badge-wrap
  .icon-badge-wrap{
    position: relative;
    &[data-num="0"]{
      .unread-num-wrap{
        display: none;
      }
    }
    .unread-num-wrap{
      content: attr(data-num);
      display: block;
      width: 18px;
      height: 18px;
      background: linear-gradient(90deg, #D1A636, #FFD361);
      font-size: 12px;
      color:#fff;
      border-radius: 50%;
      position: absolute;
      top: 4px;
      right: -8px;
      @include flex(center, center);
    }

    .unread-overlow{
      color: #FFD361;
      position: absolute;
      bottom: 100%;
      left: 100%;
    }

    &.is-unread-overflow{
        .unread-num-wrap{
          width: 20px;
          height: 20px;
        }
        .unread-num{
          display: inline-block;
          transform: scale(calc(11/ 12 ));
        }
    }
  }
  // icon-badge-wrap /


  // main-swiper
  .main-swiper{
    .swiper-slide{
      &:before{
        content:'';
        display:block;
        padding-top: calc( 210 / 375 * 100%);
      }
    }
    .swiper-item{
      background: #dedede;
      background-size: contain;
      background-position: center;
      @include absolute(0,0,0,0);
    }
  }
  // main-swiper /


  // tiny-more-btn
  // tiny-more-btn
  .tiny-more-btn{
    background: #fff;
    border-radius: 20px;
    font-size: 0.857rem;
    display: inline-flex;
    align-items: center;
    padding: 3px 10px;
    font-weight: bold;
    min-width: 70px;
    i {
      font-size: 1em;
      display: inline-block;
      transform: scale(calc(11 / 12));
      margin-left: 3px;
    }
  }
  // tiny-more-btn /

  // info-card
  .info-card{
    display: block;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 3px 10px #3333331A;
    font-size: 1rem;
    .card-media{
      background-size: cover;
      background-position: center;
      &:before{
        content:'';
        display: block;
        padding-top: calc(82 / 167 * 100%);
      }
    }
    .card-main{
      background:#fff;
      padding: 10px;
    }
    .card-title{
      font-size: 1em;
      margin-bottom: 5px;
      @include text-lines-ellipsis(1.4em, 2);
    }
    .card-info-bar{
      display: flex;
      align-items: center;
      color: #D5A240;
      font-size: 0.8571em;
      i {
        margin-right: 5px;
        font-size: 14px;
      }
    }
  }
  // info-card /


  // toolbar-menu
  .toolbar-menu{
    background: #fff;
    height: 63px;
    box-shadow: 0 -10px 20px #3333331A;
    &:before, &:after{
      display: none!important;
    }
    &.toolbar-hidden{
      transform: translate3d(0, 130%, 0);
    }
    .toolbar-inner{
        overflow: visible;
    }
    .col-item{
      width: 20%;
      &.is-active{
        i, .txt{
          color: $color-primary;
        }
      }
    }
    .menu-link{
      flex-direction: column;
      line-height: 1.3;
      position: relative;
    }
    i {
      display: block;
      font-size: 23px;
      margin-bottom: 6px;
    }
    .txt{
      font-size: 12px;
    }
    .link{
      // md 處理
      &:before{
        opacity: 1;
        top: auto;
        left: auto;
      }
    }
    .main{
      .menu-link{
        &:before{
          content:'';
          display: block;
          position: absolute;
          bottom: 17px;
          background: linear-gradient(92deg, #B88931, #F3C454);
          border-radius: 15px;
          box-shadow: 0 0 10px #FFD361;
          transform: rotate(27deg);
          z-index: -1;
          height: 55px;
          width: 55px;
        }
        i {
          color:#fff;
          font-size: 26px;
        }
      }
    }
  }
  // toolbar-menu /


  // gray-bed
  .gray-bed{
    padding: 6px 15px;
    background:linear-gradient(180deg, #FDFDFC, #EBEBEB);
    border: 3px solid #fff;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
    &.sty-shadow{
      box-shadow: 0 3px 6px #00000029;
    }
    &.sty-shadow2{
      box-shadow: 0 4px 33px #2223332E;
    }
    &.sty-slash{
      overflow:hidden;
      &:before{
        content:'';
        display: block;
        background-image: url(~@/assets/images/general/left-leash.png);
        background-repeat: no-repeat;
        @include absolute(0,0,0,0);
        left: -35px;
        background-position: left bottom;
        height: calc(100% + 30px);
        z-index: 0;
        width: calc(100% + 100px);
        pointer-events: none;
      }
    }

    &.sty-slash2{
      overflow:hidden;
      &:before{
        content:'';
        display: block;
        background-image: url(~@/assets/images/general/left-leash.png);
        background-repeat: no-repeat;
        background-size: cover;
        @include absolute(0,0,0,0);
        left: -40px;
        background-position: left bottom;
        height: calc(100% + 60px);
        z-index: 0;
        width: calc(100% + 150px);
        pointer-events: none;
        transform: rotate(16deg);
      }
    }

    &.sty-radius-14{
      border-radius: 14px;
    }

    .gray-bed-content{
      position: relative;
      z-index: 1;
    }
  }
  // gray-bed /

  // deposit-bed
  .deposit-bed{
    font-size: 16px;
    padding: 12px 15px;
    @include flex(space-between, center);
    .deposit-info{
      display: flex;
      margin-right: 10px;
    }
    .deposit-num{
      color: $color-primary;
      font-weight: bold;
    }
    .info-title{
      margin-right: 10px;
    }
    .bed-btn{
      width: auto;
      display: inline-flex;
      min-width: 86px;
      font-size: 14px;
      border-radius: 22px;
      min-height: 30px;
    }

    @include mobile-down{
      font-size: 14px;
    }
  }
  // deposit-bed /

  // hill
  .hill-content{
    background:#fff;
    border-radius: 30px 30px 0 0;
    box-shadow: 0 -21px 33px #2223332E;
    position: relative;
    margin-top: -30px;
    z-index: 1;
    // &:before{
    //   content:'';
    //   display: block;
    //   position: absolute;
    //   top: 100%;
    //   left: 0;
    //   right:0;
    //   height: 30px;
    //   background:#fff;
    // }
  }
  // hill /

  // product-pic-swiper
  .product-pic-swiper{
    position: relative;
    &:before{
      content:'';
      display: block;
      padding-top: 100%;
    }
    .swiper-pagination-fraction{
        display: flex;
        left: auto;
        right: 10px;
        bottom: 20px;
        background: #A8A8AB;
        width: auto;
        color: #fff;
        border-radius: 22px;
        padding: 2px 8px;
        font-size: 13px;
        
        letter-spacing: 1px;
    }
    .swiper-container{
      @include absolute(0,0,0,0);
    }
    .swiper-item{
      @include absolute(0,0,0,0);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  // product-pic-swiper /

  // swiper
  .swiper-pagination-liftup-30px{
    .swiper-pagination{
        transform: translateY(-30px);
    }
  }
  // swiper /

  // msg-wrap
  .msg-wrap{
    text-align: center;
    .msg-title{
      margin: 0;
      margin-bottom: 5px;
    }
    .msg-subtitle{
      font-weight: bold;
      font-size: 16px;
    }
    .msg-main-img{
      max-width: 168px;
      margin-left:auto;
      margin-right:auto;
    }
    // spacer
    .msg-v-spacer-50px{
      height: 50px;
    }
    .msg-v-spacer-40px{
      height: 40px;
    }

  }
  // msg-wrap /

  // news-card
  .news-card{
    display: block;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 3px 10px #3333331A;
    margin-bottom: 15px;
    .card-media{
      &:before{
        content:'';
        display: block;
        padding-top: calc(170 / 345 * 100%);
      }
      background-size: contain;
      background-position: center;
      img {
        max-height: 100%;
        @include absolute(0,0,0,0);
        margin: auto;
      }
    }
    .card-main{
      padding: 10px 15px;
      background:#fff;
    }
    .card-title{
      margin: 0;
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: bold;
    }
    .card-info-bar{
      color:$color-primary;
      display: flex;
      align-items: center;
      font-size: 12px;
      i{
        font-size: 16px;
        margin-right: 5px;
        margin-top: -1px;
      }
    }
  }
  // news-card /
  
  // pagination-simple
  .pagination-simple{
    margin-bottom: 15px;
    ul{
      list-style: none;
      padding:0;
      margin:0;
      @include flex(center, center);
    }

    a {
      width: 30px;
      height: 30px;
      @include flex(center,center);
      font-size: 12px;
      border: 1px solid #DDDDDD;
      background: #fff;

      i {
        display: inline-block;
        transform: scale(calc(8 / 12));
      }
    }
    
    .pagination-item{
      + .pagination-item{
        margin-left: 5px;
      }

      &.is-active{
        a {
          background: $color-primary;
          color:#fff;
        }
      }
    }

    .back.ultra, .next.ultra{
      width: 38px;
    }
  }
  // pagination-simple /

  // lined-list
  .lined-list{
    margin-bottom: 15px;

    &.top-line{
      border-top: 1px solid $color-divider-line;
    }

    &.no-bottom-line{
      li:last-child{
        border-bottom: 0;
      }
    }

    ul{
      margin: 0;
      padding: 0;
      list-style: none;
    }
    li {
      border-bottom: 1px solid $color-divider-line;
    }
  }
  // lined-list /

  // notification-slip
  .notification-slip{
    @include flex(center,center);
    padding-left: 20px;
    padding-top: 13px;
    padding-bottom: 13px;
    position: relative;
    font-size: 1.142857rem;
    &.is-unread{
      .slip-title{
        &:before{
          content: '';
          display: block;
          width: 10px;
          height: 10px;
          background: $color-primary;
          position: absolute;
          left: -20px;
          top: .3em;
          border-radius: 50%;
        }
      }
    }

    &.sty-custom-control{
      padding-left: 0;
      .slip-side{
        padding-right: 10px;
      }
      .base-checkbox{
        i {
          font-size: 14px;
        }
      }
    }

    .slip-main{
      flex-grow: 1;
    }
    .slip-side{
      flex-shrink: 0;
      color: #DDDDDD;
      .fa-clock-o {
          font-size: 0.857em;
      }
    }
    .slip-title{
      font-size: 1em;
      margin: 0;
      margin-bottom: 10px;
      position: relative;
      font-weight: normal;
    }
    .slip-info-bar{
      display: flex;
      align-items: center;
      color: $color-primary;
      font-size: 0.857em;
      i {
        font-size: 1em;
        margin-top: -1px;
        margin-right: 5px;
      }
    }
  }
  // notification-slip /

  // order-info-card
  .order-info-card{
    display: block;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 3px 10px #3333331A;
    font-size: 1rem;
    padding: 5px 10px;
    background: #fff;
    margin-bottom: 10px;

    @include media-up(550){
      .card-body{
        .card-media{
          width: calc(70 / 325 * 100%);
        }
      }
    }

    .card-info-bar{
      padding: 10px 0;
      &.sty-2side{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .card-body{
      display: flex;
      padding: 10px 0;
      border-top: 1px solid $color-divider-line;
      border-bottom: 1px solid $color-divider-line;
      .card-media{
        flex-shrink: 0;
        width: calc(60 / 325 * 100%);
        min-width: 60px;
        max-width: 150px;
        margin-right: 10px;
        position: relative;
        &:before{
          content:'';
          display: block;
          padding-top: 100%;
        }
        background-size: contain;
        background-position: center;
        > img {
          max-height: 100%;
          @include absolute(0,0,0,0);
          margin: auto;
        }
      }
      .img-fallback{
        img{
          width: 50px;
          right: 5px;
        }
      }
      .card-main{
        flex-grow: 1;
      }
    } 

    .card-title{

    }

    .card-main{
      display: flex;
      flex-direction: column;
    }

    .card-main-info-bar{
      margin-top: auto;
      &.sty-2side{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    // 其他
    .info-status{
      color:$color-primary;
    }
    .info-money{
      color:$color-primary;
      font-weight: bold;
    }
    .info-num{
      color: #A8A8AB;
    }
  }
  // order-info-card /

  // order-item-card
  .order-item-card-list{
    li {
      padding: 10px 0;
    }
  }
  .order-item-card{
    display: flex;
    
    .card-media{
      position: relative;
      margin-right: 10px;
      width: 60px;
      background: $color-media-bg;
      &:before{
        content:'';
        display: block;
        padding-top: 100%;
      }
      img{
        max-height: 100%;
        @include absolute(0,0,0,0);
        margin:auto;
      }
      background-size: contain;
      background-position: center;

      .img-fallback{
        img{
          width: 50px;
          right: 5px;
          left: auto;
        }
      }
    }

    .card-main{
      flex-grow:1;
      display: flex;
      flex-direction: column;
    }

    .card-title{

    }
    .card-info-bar{
      margin-top: auto;
      &.sty-2side{
        display: flex;
        justify-content: space-between;
      }
    }

    // 其他
    .info-num{
      color: #A8A8AB;
    }
  }
  // order-item-card /

  // hori-info-box
  .hori-info-box, .account-statistics-preview{
    text-align: center;
    padding: 10px 0;
    .row, .bs-row{
      align-items: center;
      justify-content: center;
      margin-left: -12px;
      margin-right: -12px;
      flex-wrap: nowrap;
    }

    &.sty-divider-line-long{
      padding: 3px 0;
      [class*="col"]{
        padding-top: 7px;
        padding-bottom: 7px;
        + [class*="col"]{
          &:before{
            top: 0;
            bottom: 0;
          }
        }
      }
    }

    [class*="col"]{
      position: relative;
      padding: 0 5px;
      +  [class*="col"]{
        &:before{
          content:'';
          display: block;
          position: absolute;
          left: 0;
          top: 5px;
          bottom: 5px;
          background-color: $color-divider-line;
          width: 1px;
        }
      }
    }

    .col-50{
      width: 50%;
    }

    .box-title, .preview-title{
      font-size: 0.857rem;
      color: #707070;
      margin-bottom: 9px;
      i {
        margin-right: 2px;
      }
    }

    .box-info, .preview-statistics{
      font-size: 1.42rem;
      font-weight: bold;
      line-height: 1;
      display: flex;
      align-items: baseline;
      justify-content: center;
    }

    .box-sm-txt{
      font-size: 14px;
    }

    .small-txt{
      font-size: 12px;
      &.left{
        margin-right: 2px;
      }
      &.right{
        margin-left: 2px;
      }
    }

    @include media(374.98) {
      i {
        display: block;
        margin-bottom: 5px;
      }
      .col{
        padding: 0 5px;
        flex-grow: 1;
      }

      .box-info, .preview-statistics{
        font-size: 16px;
      }
    }

  }
  // hori-info-box /

  // pesudo-panel-block
  .pesudo-panel-block{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    // 使page scroll 正常運作
    min-height: 100%;

    &.panel-top-21pcrt{
      .block-content:before{
        top: 21%;
      }
    }

    &.panel-top-26pcrt{
      .block-content:before{
        top: 26%;
      }
    }

    .block-static{
    }
    .block-content{
      flex-grow: 1;
      position: relative;
      &:before{
        content:'';
        display: block;
        position: absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
        background-color: #fff;
        box-shadow: 0 4px 33px #2223332E;
        border-radius: 33px 33px 0 0 ;
      }
    }
    .block-content-inner{
      position: relative;
      z-index: 1;
    }
  }
  // pesudo-panel-block /

  // gray-panel
  .gray-panel{
    background: linear-gradient(180deg, #FDFDFC, #EBEBEB);
    box-shadow: 0 2px 24px #00000029;
    border-radius: 30px 30px 0 0;
    overflow: hidden;
    position: relative;

    &.sty-auto-grow{
      flex-grow: 1;
      min-height: 100%;
    }

    &.bottom-left-slash{
      &:before{
        pointer-events: none;
        z-index: 0;
        content:'';
        display: block;
        @include absolute(0,0,0,0);
        left: -30px;
        background: url(~@/assets/images/general/left-leash.png);
        background-size: calc(100% + 30px) calc(100% + 30px);
      }
    }

    .panel-content{
      position: relative;
      z-index: 1;
    }
  }
  // gray-panel /

  // social-grape
  .social-grape{
    .link{
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      + .link{
        margin-left: 20px;
      }
    }
    .social-txt{
      margin-left: 5px;
      font-size: 14px;
    }
  }
  // social-grape /

  // head-card
  .head-card{
    border-radius: 14px;
    box-shadow: 0 3px 5px #22233329;
    overflow: hidden;
    .sty-2side{
      display: flex;
      justify-content: space-between;
    }

    &.no-bottom-radius{
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.sty-has-badge-bar{
      .card-body{
        padding-bottom: 8px;
      }
      .card-badge-bar{
        padding-top: 3px;
      }
    }

    &.sty-auto-grow{
      height: 100%;
      flex-grow: 1;
      flex-direction: column;
      display: flex;
      .card-body{
        flex-grow: 1;
      }
    }

    &.sty-slash{
      .card-body{
        background:  url(~@/assets/images/general/left-leash.png), linear-gradient(180deg, #FDFDFC, #EBEBEB);
        background-size: calc(100% + 80px) calc(100% + 590px);
        background-position: -80px bottom;
      }
    }

    .card-head{
      background: #DBDBDB;
      color: #707070;
      font-size: 12px;
      font-weight: bold;
      padding: 12px 18px;
    }
    .card-body{
      padding: 15px;
      background:#fff;
    }

    // 其他
    .card-badge-bar{
      text-align: right;
      .badge{
        margin-bottom: 5px;
        padding-left: 9px;
        padding-right: 9px;
        color:#A46F0C;
        font-weight: bold;
        + .badge{
          margin-left: 7px;
        }
      }
    }
    
    .head-card-select{
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      color: #000000;
      position: relative;
      .select-txt{
        margin-right: 4px;
        font-weight: normal;
      }
      &:before{
        content:'';
        display: block;
        @include icomoon('\e917');
        position: absolute;
        right: 0;
        font-size: 12px;
        transform: scale(calc(5 / 12));
        color: #fff;
      }
      &:after{
        content:'';
        display: block;
        width: 20px;
        height: 20px;
        background: linear-gradient(180deg, #D1A636, #FFD361);
        border-radius: 50%;
        @include flex(center, center);
      }
    }

  }
  // head-card /

  // wallet-info-slip
  .wallet-info-slip-list{
    li{
      padding: 0 9px;
      padding-top: 7px;
      padding-bottom: 3px;
      &:first-child{
        padding-top: 0;
      }
    }
  }
  .wallet-info-slip{
    table{
      width:100%;
    }
    td:last-child:not(:only-child){
      text-align: right;
    }
    td, th{
      height: 22px;
    }

    // 其他
    .wallet-out{
      color: $color-money-hilight;
      font-weight: bold;
      &.wallet-out-green{
        color: #2AA400;
      }
    }
    .wallet-in{
      color: #2AA400;
      font-weight: bold;
    }
    .wallet-date{
      color: #707070;
    }
    .wallet-balance{
      color: #707070;
    }
  }
  // wallet-info-slip /

  // ui-input
  .ui-input{
    background:#fff;
    box-shadow: 0 3px 10px #3333331A;
    border-radius: 6px;
    overflow:hidden;
    position: relative;
    input{
      padding: 14px 17px;
      font-size: 16px;
      font-weight: bold;
      width: 100%;
      &::placeholder{
        color: #A8A8AB;
      }
    }
    &:before{
      content:'';
      display: block;
      position: absolute;
      top:0;
      bottom:0;
      right: 0;
      width: 21px;
      background: linear-gradient(180deg, #D1A636, #FFD361);
      pointer-events: none;
      z-index: 1;
    }
    &:after{
      content:'';
      display: block;
      @include icomoon('\e917');
      position: absolute;
      font-size: 12px;
      right: 10.5px;
      top: 50%;
      pointer-events: none;
      transform: translateY(-50%) translateX(50%) scale(calc(6.22 / 12));
      z-index: 2;
      color:#fff;
    }
  }
  // ui-input /

  // slip-custom-control
  .slip-custom-control-list{
    li{
      padding: 6px 0;
    }
  }
  .slip-custom-control{
    position: relative;
    padding-left: 12px;
    display: block;
    .custom-control-input{
      display: none;
      &:checked ~ .custom-control-inner{
        color:$color-primary;
        &:before{
          content:'';
          display: block;
          width: 8px;
          height: 8px;
          background: $color-primary;
          border-radius: 50%;
          position: absolute;
          top: 11px;
          left: 0;
        }

        // other stuff
        .title {
          color: $color-primary;
        }
        .badge{
          background: $color-primary;
          color:#fff;
        }
      }
    }
  }
  // slip-custom-control /

  // checkout-box
  .checkout-box{
    margin-bottom: 15px;
    font-size: 1rem;
    table{
      width: 100%;
    }

    th, td{
      padding-top: 3px;
      padding-bottom: 3px;
    }

    th{
      text-align:left;
      font-weight: normal;
    }

    td{
      font-size: 1em;
      text-align: right;
      font-weight: bold;
    }

    .grand-total-bar{
      th, td{
        padding-top: 12px;
        padding-bottom: 12px;
        border-top: 2px solid #E3E3E3;
        color: $color-primary;
        font-size: 1em;
        font-weight: bold;
      }
    }
  }
  // checkout-box /

  // org-chart
  .org-seed{
    display: inline-block;
    position: relative;
    padding-left: 24px;
    > ul {
      padding-left: 10px;
      list-style: none;
    }

    $sty-origin-topdown-half-length: calc(50% - 11px /* node mb + node-txt*/ - 0.5px /*水平線 50%*/);
    
    .node-badge{
    }
    
    // node 線
    &.sty-origin1, &.sty-origin2{
      // parent 線
      &:before{
        content:'';
        display: block;
        background: rgba(#707070, .5);
        position:absolute;
        width: 24px;
        height: 1px;
        left: calc(0% + 0.5px /*垂直線 50%*/);
        top: $sty-origin-topdown-half-length
      }

      .seed-node{
        padding: 5px 0;
        &:before{
          content:'';
          display: block;
          background: rgba(#707070, .5);
          position:absolute;
          width: 10px;
          height: 1px;
          right: 100%;
          top: $sty-origin-topdown-half-length;
        }
        &:after{
          content:'';
          display: block;
          background: rgba(#707070, .5);
          position:absolute;
          width: 1px;
          right: calc(100% + 10px);
          top: 0;
          bottom: 0;
        }
      }

      > ul {
        > li {
          display: flex;
          &:only-child{
            // 只有一個
            // 則不顯示側邊border
            > .seed-node{
              &:after,&:before{
                display: none;
              }
            }
          }
        }
      }
  
      li:first-child{
        > .seed-node{
          &:after{
            top: $sty-origin-topdown-half-length;
          }
        }
      }
      li:last-child{
        > .seed-node{
          &:after{
            bottom: calc(100% - #{$sty-origin-topdown-half-length} - 1px);
          }
        }
      }
  
      // 其他
      > ul > li {
        > .seed-node{
          width: 55px;
          text-align: center;
          display: inline-flex;
  
          flex-direction: column;
          justify-content: center;
          position: relative;
          .node-badge{
            display: inline-block;
            background: #F3C454;
            padding: 5px 9px;
            border-radius: 4px;
            text-align: center;
            font-size: 16px;
            margin-bottom: 5px;
            position: relative;
            white-space: nowrap;
          }
          .node-txt{
            min-height: 16px;
            color: rgba(#000000, .5);
            font-size: 12px;
            display: inline-block;
            word-break: break-word;
            span{
              word-break: keep-all;
              white-space: nowrap;
            }
          }
        }
      }
    }

    &.sty-final{
      transform: translateY(-10px);
      display: flex;
      align-items: center;
      // parent 線
      &:before{
        content:'';
        display: block;
        background: rgba(#707070, .5);
        position:absolute;
        width: 24px;
        height: 1px;
        left: calc(0% + 0.5px /*垂直線 50%*/);
        top: calc(50% - 0.5px);
      }

      .seed-node{
        padding: 6px 0;
        display: flex;  
        align-items: center;
        position: relative;
        &:before{
          content:'';
          display: block;
          background: rgba(#707070, .5);
          position:absolute;
          width: 10px;
          height: 1px;
          right: 100%;
          top: calc(50% - 0.5px);
        }
        &:after{
          content:'';
          display: block;
          background: rgba(#707070, .5);
          position:absolute;
          width: 1px;
          right: calc(100% + 10px);
          top: 0;
          bottom: 0;
        }

        .node-title{
          display: flex;
          align-items: center;
          margin-right: 20px;
          width: 41px;
          flex-shrink: 0;
          &:before{
            content:'';
            display: block;
            width: 9px;
            height: 28px;
            background: #F3C454;
            border-radius: 4px;
            margin-right: 4px;
            flex-shrink: 0;
          }
        }
        .node-txt{
          word-break: break-all;
        }
      }

      > ul {
        > li {
          display: flex;
          &:only-child{
            // 只有一個
            // 則不顯示側邊border
            > .seed-node{
              &:after,&:before{
                display: none;
              }
            }
          }
        }
      }

      li:first-child{
        > .seed-node{
          &:after{
            top: calc(50% - 0.5px);
          }
        }
      }
      li:last-child{
        > .seed-node{
          &:after{
            bottom: calc(50% - 0.5px);
          }
        }
      }
    }

    &.sty-origin1 {
      padding-left:0;
      &:before{
        display: none;
      }
      > ul > li {
        > .seed-node{
            .node-badge{
              background: #8E6410;
              color:#fff;
            }
        }
      }
    }

    // 角色
    .seed-node.seed-node{
      // 創聯
      &.sty-1{
        .node-badge{
          background:#8E6410;
          color:#fff;
        }
        .node-title{
          &:before{
            background: #8E6410;
          }
        }
      }

      // 總代
      &.sty-2{
        .node-badge{
          background:#F3C454;
          color: $color-font;
        }
        .node-title{
          &:before{
            background: #F3C454;
          }
        }
      }

      // 區代
      &.sty-3{
        .node-badge{
          background:#0E69AE;
          color:#fff;
        }
        .node-title{
          &:before{
            background: #0E69AE;
          }
        }
      }

      // 經銷
      &.sty-4{
        .node-badge{
          background:#47BED6;
          color:#fff;
        }
        .node-title{
          &:before{
            background: #47BED6;
          }
        }
      }

      // 一般
      &.sty-5{
        .node-badge{
          background:#AEAEAE;
          color:#fff;
        }
        .node-title{
          &:before{
            background: #AEAEAE;
          }
        }
      }

    }

  }
  // org-chart /

  // text-editor
  .text-editor-title1{
    font-weight: bold;
    font-size: 17px;
    margin-bottom: .5em;
  }

  .text-editor-list-item{
    margin-bottom: 10px;
    .item-title{
      flex-shrink: 0;
    }
  }

  .text-editor-v-spacer{
    height: 20px;
  }
  // text-editor /


  .text-red\!{
    color: #ff5970!important;
  }

  .text-blue\!{
    color: #0e69ae!important;
  }

  .text-black\!{
    color: #333!important;
  }

}
// 新建元件 /